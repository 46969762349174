import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { SampleWorkflow } from 'state-domains/domain/subscription';

import { buildSampleWorkflowsUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken, convertToSnake } from '../../../utils';
import { ConfigurationCreateWorkflowRequest } from '../types';

export const addConfigurationSampleWorkflow = (
    payload: Partial<ConfigurationCreateWorkflowRequest>,
): Observable<SampleWorkflow> => {
    const url = buildSampleWorkflowsUrl();
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<SampleWorkflow>) => {
            const result = convertToCamel<SampleWorkflow>(response);
            return observableOf(result);
        }),
    );
};
